.edit_post {
  text-align: center;
}

.edit_post h2 {
  font-size: 2.2em;
}

.edit_post p {
  color: #aaa;
  margin-bottom: 2em;
}

.edit_post .preview_title {
  margin-bottom: 0.2em;
  color: #000;
  font-weight: bold;
}

.image_preview {
  max-width: 100%;
  margin-bottom: 1em;
}
