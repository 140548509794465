* {
  box-sizing: border-box;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 300px;
}

.progressDone {
  background: linear-gradient(to left, #efc314, #1c5dcd);
  box-shadow: 0 3px 3px -5px #efc314, 0 2px 5px #1c5dcd;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.CircularProgressbarPath {
  transition: 1s ease 0.3s;
}

.CircularProgressbarText {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUpAnimation {
  animation: 4s fadeInUp;
  font-weight: bold;
}
