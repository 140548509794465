.backgroundImageBottom {
  background: 
    /* Gradiente de azul para branco */ url('img/footer_img.jpg')
    bottom center no-repeat; /* Imagem de fundo posicionada no bottom center */
  background-size: contain; /* Ajusta o tamanho da imagem para que ela seja contida na área */
  position: relative;
  height: 100vh;
  width: 100%;
}

.backgroundGradient {
  background: linear-gradient(to bottom, #0055bb 80%, #ffffff 100%);
}

.backgroundImageBottom::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-image: url('img/footer_img.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain; /* Ajuste para 'cover' se quiser que a imagem cubra toda a largura */
}

.symbol {
  box-shadow: 0 0 10px yellow, 0 0 20px yellow, 0 0 30px yellow;
  border-radius: 50%;
  object-fit: cover;
}
