footer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: rgb(67, 136, 219);
  background: linear-gradient(
    0deg,
    rgba(67, 136, 219, 1) 0%,
    rgba(0, 85, 187, 1) 100%
  ); */

  /* position: absolute; */
  bottom: 10px;
  width: 100%;
  height: 100px;
}
footer.homeFooter {
  background-color: transparent;
  padding: 20px 0;
  text-align: center;
  position: relative;
  /* bottom: -330px; */
}

.iconSocialMedia {
  width: 20px;
  opacity: 0.4;
  margin: 0 15px;
}

.iconSocialMedia:hover {
  opacity: 0.9;
}

body {
  display: flex;
  flex-direction: column;
}

.container-full {
  flex: 1;
}

@media only screen and (max-width: 600px) {
  footer.homeFooter {
    background-color: #0055bb;
    /* bottom: -790px; */
    position: relative;
    z-index: 100;
  }
  footer {
    position: relative;
    bottom: 0px;
  }
}
