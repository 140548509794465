@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css');

.input_text .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa_lock {
  position: absolute;
}

.input_text {
  position: relative;
  margin-top: 30px;
}
