.post_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.post_container h3 {
  margin-bottom: 0.2em;
}

.post_container .tags {
  display: flex;
  justify-content: center;
}

p {
  margin-right: 1em;
  text-align: left !important;
}

.tags span {
  font-weight: bold;
}

.content {
  max-width: 100%;
  width: 60%;
  justify-content: center;
  margin-bottom: 2em;
}
