@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css');

.login {
  text-align: center;
}

.login p {
  color: #aaa;
}

.logo {
  background-color: white;
  position: absolute;
  width: 250px;
  height: 210px;
  top: 150px;
  left: 25%;
}

.logo svg {
  width: 85%;
  position: relative;
  top: 36px;
  left: 17px;
}
