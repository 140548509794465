.icon_container {
  width: 2rem;
  display: flex;
  justify-content: center;
  height: 2rem;
  position: relative;
  color: #10162f;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid rgb(132 127 127);
  border-radius: 50%;
}
.icon_container img {
  width: 80%;
  padding: 4px;
}

a.link_account {
  text-decoration: none;
  width: 150px;
  height: 40px;
  background-color: #005bbb;
  color: #fff;
}

a.link_account:hover {
  color: #ffd726;
  background-color: #005bbb;
}

.accBlue {
  background-color: #005bbb !important;
  border-radius: 0% !important;
}
.accBlue::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.bgYellow {
  background-color: #f3eac3;
}

.textGray {
  color: rgb(47, 55, 65) !important;
}

.card_courses {
  border: 1px solid #104fcb;
  box-shadow: 10px 10px 5px -8px rgba(207, 203, 207, 1);
  text-decoration: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 15px;
  width: 280px !important;
}

.card_courses:hover figcaption:before,
.card_courses.hover figcaption:before {
  right: -5px;
}

/* .snip1571 {
  background-color: #000;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin: 10px;
  max-width: 315px;
  min-width: 230px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
} */
a.card_courses *,
a.card_courses *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.card_courses img {
  backface-visibility: hidden;
  max-width: 75%;
  vertical-align: top;
}
a.card_courses:hover {
  -webkit-box-shadow: 10px 10px 5px -8px rgb(126, 123, 126);
  -moz-box-shadow: 10px 10px 5px -8px rgb(42, 30, 213);
  box-shadow: 10px 10px 5px -8px rgb(72, 55, 220);
}
.card_courses figcaption {
  top: 70px;
  left: 0;
  position: absolute;
  z-index: 0;
}
.card_courses figcaption:before {
  background-color: #005bbb;
  bottom: -5px;
  content: '';
  left: 0;
  position: absolute;
  right: 100%;
  top: -5px;
  z-index: -1;
}
.card_courses h4 {
  background-color: #fad20e;
  color: #005bbb;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  padding: 5px 15px;
  text-transform: uppercase;
  z-index: 1;
}
.card_courses a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.card_courses.hover > h4 {
  opacity: 1;
}

.card_courses:hover figcaption:before,
.card_courses.hover figcaption:before {
  right: -5px;
}

.card_courses .iconLevel {
  width: 15px;
}
.interactiveCard {
  top: 110px !important;
}

@media only screen and (max-width: 600px) {
  .interactiveCard {
    top: 90px !important;
  }
}
