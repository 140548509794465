@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300&family=Lato:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&family=Open+Sans:ital,wght@0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500&family=Open+Sans:ital,wght@0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

body {
  background-color: #0055bb;
  margin: 0;
  font-family: 'Barlow', sans-serif !important;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #777;
  margin-bottom: 0px;
}

html {
  height: 0%;
  margin: 0;
  padding: 0;
}
.lessonContent {
  /* height: 100vh; */
}

input::placeholder,
textarea::placeholder {
  font-family: 'Barlow', sans-serif;
  color: #aaa;
}

input:focus,
textarea:focus {
  outline: none;
}

.bg-lightblue {
  background-color: #81afef;
}
.bg-lightYellow {
  background-color: #fff9d3;
}
a,
nav button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: #000;
  transition: 0.4s;
  font-size: 1em;
  cursor: pointer;
  font-family: 'Barlow', sans-serif;
}

a:hover,
nav button:hover {
  color: #bbb;
}

/* form styles */
form {
  max-width: 90%;
  margin: 0 auto;
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: #777;
}

label span {
  margin-bottom: 0.3em;
  font-weight: bold;
  text-align: left;
}

input,
textarea {
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 7px;
  border-color: #aaa;
  padding: 0.8em 0;
  padding-left: 15px;
  background-color: transparent;
}

.btn {
  background-color: #2869ff;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  width: 120px;
  font-weight: bold;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
}

h2 {
  color: #005bbb;
}

.btn.btn-dark {
  background-color: #1844a3;
}

.btn.btn-outline {
  background-color: transparent;
  border-radius: 0;
  color: #000;
  border: 1px solid #000;
  padding: 7px 30px;
}
h1,
h2,
h3,
h5 {
  font-weight: bold !important;
}
h1 {
  color: #0055bb !important;
}
.btn:hover {
  background-color: #2869ff;
  color: #fff;
}

.btn.btn-outline {
  background-color: #2869ff;
}

.btn.btn-danger:hover {
  background-color: #d1536a;
}

button[disabled] {
  background-color: #7c7a7a !important;
  color: #fff !important;
}
.btn[disabled] {
  background-color: #7c7a7a !important;
  color: #fff !important;
}
.pagination .btn {
  border: solid 3px var(--bs-primary) !important;
  font-weight: 500;
  width: 70px;
}
.pagination .btn[disabled] {
  border: solid 3px #7c7a7a !important;
}

.container .error {
  color: #d1536a;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 5px;
  border-radius: 5px;
}

nav.nav_main a {
  text-decoration: none;
  color: #050505;
  font-weight: 500;
}

nav.nav_lesson a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

nav.nav_main a:hover,
nav.nav_lesson a:hover {
  color: var(--bs-primary);
}

#sidebar.active {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40%;
  height: 100%;
  background: #fff;
  transition: all 300ms linear;
}
#sidebar {
  left: -40%;
  width: 40%;
  position: absolute;
  top: 0px;
  height: 100%;
  background: #fff;
  transition: all 300ms linear;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  z-index: 9999999;
}
.lesson-sidebar .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
.lesson-sidebar .modal-dialog {
  display: inline-block;
  left: 0px;
  top: 0px;
  margin-top: 0px !important;
  height: 100vh !important;
  width: 500px !important;
  background-color: #fff;
}

#sidebar .toggle-btn {
  position: absolute;
  left: 220px;
  top: 10px;
}
#sidebar .toggle-btn span {
  display: block;
  width: 30px;
  height: 5px;
  background: #151719;
  margin: 5px 0px;
  cursor: pointer;
}
#sidebar div.list div.item {
  padding: 15px 10px;
  border-bottom: 1px solid #444;
  color: #0f3a5d;
  text-transform: uppercase;
  font-size: 12px;
}

#sidebar div.list div.item a {
  color: #fff;
  text-decoration: none;
}
a.lesson_list {
  text-decoration: none;
  color: #0f3a5d;
}

a.lesson_list:hover {
  background-color: #0055bb;
  padding: 10px;
  color: #ffffff !important;
}

.accordion_lesson .accordion-button:not(.collapsed) {
  font-weight: 500;
  background-color: #cedbea;
  box-shadow: none;
}

.accordion_lesson .accordion-button {
  font-weight: 500;
  background-color: #fff;
  box-shadow: none;
}

.accordion_lesson .current {
  background-color: #eee;
}
.accordion_lesson .section_list {
  font-weight: 500;
  margin-top: 0.5em;
}
.accordion_lesson,
.accordion_lesson .accordion-item {
  background-color: #fff;
}
div.icon-lesson::before {
  display: inline-block;
  content: '👉';
  width: 27px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -40px;
}
.bg-blue-1 {
  background-color: #3d88db;
}
/* .dataContainer {
  height: 600px;
} */

.container-pagination {
  top: 20px;
  right: 0px;
}

.col-buttons {
  width: 90px !important;
}

.bg-yellow {
  background-color: #fad20e;
}
.bg-yellow-light {
  background-color: #fde747;
}

footer {
  margin-top: auto;
}

.login-wrap {
  top: 107px;
  position: absolute;
  left: 8%;
  width: 1000px;
}

.cards-home {
  position: absolute;
  bottom: 0px;
}
.box-highlight {
  position: relative;

  left: 50px;
  top: -101px;
  height: 100%;
  z-index: 100;
}

.text-hgl {
  font-weight: 500;
  color: #0055bb;
  font-size: 23px;
  line-height: 0px;
}

.bg-home {
  background-image: url('/src/pages/Home/img/friends-coding.png'); /* The image used */
  background-position-x: 100%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: 57%;
  position: relative;
  height: 570px;
}
.navbar.nav_main {
  z-index: 99999;
}

body:has(.dataContainer) {
  background: #fff;
}

.bg-white-yellow {
  background: linear-gradient(
    to bottom,
    white 0%,
    white 50%,
    #fde747 70%,
    #fde747 100%
  );
  height: 80%;
}

.box-about {
  right: 30px;
  top: 30px;
}

#accordionModules .accordion-module {
  color: #233a62;
  font-weight: 600;
  background-color: #fff;
}

#accordionModules .accordion-item {
  border-top: 1px solid #6c9ef3;
  border-left: 1px solid #6c9ef3;
  border-right: 1px solid #6c9ef3;
  border-bottom: 1px solid #6c9ef3;
  border-radius: 0px;
}

#accordionModules .accordion-module:not(.collapsed) {
  box-shadow: none;
}

#accordionModules .accordion-module:hover {
  background-color: var(--bs-accordion-active-bg);
}
#accordionModules .accordion-collapse.collapse.show {
  border-radius: 0px;
}

#accordionModules .accordion-body-module {
  background-color: #fff;
}

#accordionModules .accordion-sections {
  background-color: #fff;
}

#accordionModules .link-lesson:hover {
  background-color: #eaeff4;
}

#accordionModules .link-lesson {
  color: #2e3a4f;
}

#home-course {
  background: #fff;
}

.bg-blue-dark {
  background-color: #223961;
}

.logo_white_letter {
  width: 100px;
  height: 45px;
  fill: #fff;
}

.wmde-markdown,
.question-section {
  -webkit-text-size-adjust: 100%;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 1.7 !important;
  word-wrap: break-word;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
}

.bg-quiz {
  background: linear-gradient(
    0deg,
    rgba(18, 44, 74, 1) 0%,
    rgba(34, 104, 156, 1) 100%
  );
}

.answer-section a {
  background-color: #0f3a5d;
  /* opacity: 0.5; */
  text-decoration: none;
  font-weight: 500;
  color: #fff;
}
.answer-section a:hover {
  background-color: #fde747;
}

.answer-section a .text {
  opacity: 1;
}

.answer-section a.disabled {
  pointer-events: none;
  cursor: pointer;
}

.answer-section a.disabled.border-green {
  border: 3px solid green;
}

.answer-section a.disabled.border-red {
  border: 1px solid red;
}

.answer-section a.disabled.border-green.filled {
  background-color: rgb(17, 72, 17);
}

.answer-section a.disabled.border-red.filled {
  background-color: rgb(107, 12, 12);
}
[data-color-mode*='light'] .wmde-markdown,
[data-color-mode*='light'] .wmde-markdown-var,
.wmde-markdown-var[data-color-mode*='light'],
.wmde-markdown[data-color-mode*='light'],
body[data-color-mode*='light'] {
  color-scheme: light;

  --color-fg-default: #181919 !important;
  --color-canvas-default: #fff !important;
  --color-neutral-muted: rgba(110, 118, 129, 0.1) !important;
  --color-prettylights-syntax-string: #4d82d3 !important;
}
[data-color-mode*='light'] .wmde-markdown span.code-line {
  color: #c1c7cd !important;
}

.wmde-markdown a {
  color: #0b3768;
}
.wmde-markdown pre > code {
  background-color: #181919 !important;
}

h5.question-text {
  white-space: break-spaces;
}

.reset-password form input {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  border: 1px solid #e0e3e9;
  padding: 0 10px;
  padding-left: 30px;
  padding-right: 27px;
  outline: 0;
}

.fa-lock {
  position: absolute;
  top: 13px;
  left: 10px;
}

.fa-eye,
.fa-eye-slash {
  position: absolute;
  top: 13px;
  right: 10px;
}

.donate_button {
  background-color: #ffd726 !important;
  color: #0055bb !important;
}

.donate_button:hover {
  background-color: #096de7 !important;
  color: #ffd726 !important;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #f2709c, #ff9472);
  box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

@media only screen and (max-width: 600px) {
  html {
    position: static;
    min-height: 100%;
    padding-bottom: 0px;
  }

  .login-wrap {
    position: static;
    left: 0%;
    width: 100%;
  }
  .container-full:has(.bg-home) {
    background: none;
  }
  .box-highlight {
    position: relative;
    left: 0px;
    top: 0px;
  }
  .modal.lesson-sidebar .modal-content {
    width: 80%;
  }
  .p-lg-4 {
    padding: 20px;
  }
  .donate-text {
    background-color: #fff;
  }
  .questionStatement {
    overflow-y: unset;
    height: 100% !important;
  }
}

.text-blue {
  color: #005bbb !important;
}

.questionStatement {
  overflow-y: auto;
  height: 60vh;
}

.bgBlue {
  background-color: #0055bb;
}

.bgGrayBlue {
  background-color: #b7cbe3;
}

.homeH1 {
  font-size: 2rem;
}

.homeH4 {
  font-size: 1.2rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .homeH4Active {
    font-size: 1rem;
  }
  .homeH1Active {
    font-size: 1rem;
  }
}
