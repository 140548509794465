.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search_form {
  max-width: 100%;
  width: 60%;
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.search_form input {
  margin-right: 10px;
  width: 50%;
}

.noposts {
  text-align: center;
}

.noposts p {
  padding: 10px 25px;
}
.accordion-body h6 {
  text-align: left !important;
}
.logo {
  background-color: white;
  position: absolute;
  width: 250px;
  height: 210px;
  top: -85px;
  left: -50px;
}

.logo svg {
  width: 90%;
}

ul.boxCourse > li {
  list-style-type: none;
  background: url(https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/buulet-arrowAtivo%201.svg?alt=media&token=35aef7bd-bcd4-4e65-b680-5506965fe865&_gl=1*1are2wh*_ga*MjAxNzU0MTM0LjE2ODUzODUyNTQ.*_ga_CW55HF8NVT*MTY4NjE1MDg1MC4xNC4xLjE2ODYxNTA4NjYuMC4wLjA.)
    no-repeat;
  padding-left: 30px;
  margin-bottom: 7px;
  background-size: contain;
}

.linkRegister {
  color: #0055bb;
}

@media only screen and (max-width: 600px) {
  .logo {
    background-color: white;
    position: static;
    width: 250px;
    height: 210px;
    top: -85px;
    left: -50px;
  }
}
