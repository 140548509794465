.post_detail {
  margin-bottom: 2em;
}

.post_detail img {
  max-width: 600px;
}

.post_detail h2 {
  margin-bottom: 0.4em;
}

.post_detail .tags {
  margin-bottom: 1.2em;
  display: flex;
}

.mark-view p {
  text-align: left !important;
  margin-bottom: 0 !important;
}

.tags span {
  font-weight: bold;
}

.createdby {
  font-style: italic;
  color: #444;
  font-size: 0.8em;
  margin-bottom: 1em;
}

.exerciseScreen p,
.exerciseScreen ul > li,
.exerciseScreen ol > li {
  font-size: 15px;
}
