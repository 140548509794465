.graphContainer {
  width: 600px;
  height: 400px;
  margin: 50px auto;
  position: relative;
  border-left: 1px solid #0c0c0c; /* Left border */
  border-bottom: 1px solid #0c0c0c; /* Bottom border */
  box-sizing: border-box;
  padding: 20px;
}

@media (max-width: 768px) {
  .graphContainer {
    width: 100%;
    margin: 20px auto;
    border: none !important;
    height: auto;
    padding: 0px;
  }
}

.quadrant {
  position: absolute;
  width: calc(50% - 15px);
  height: calc(50% - 15px);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 11px;
  white-space: pre-wrap;
  margin: 10px; /* Margin between quadrants */
}

@media (max-width: 768px) {
  .quadrant {
    width: calc(100% - 30px);
    height: calc(50% - 15px);
    margin: 5px;
    position: relative !important;
  }
  .quadrant1 {
    background-color: #40739d;
  }

  .quadrant2 {
    background-color: #0097b2;
  }

  .quadrant3 {
    background-color: #ff5757;
  }

  .quadrant4 {
    background-color: #d1a91a;
  }
  .axisLabels,
  .horizontalAxisLabel,
  .verticalAxisLabel {
    display: none !important;
  }
}

.quadrant1 {
  background-color: #40739d;
  top: 0;
  left: 0;
}

.quadrant2 {
  background-color: #0097b2;
  top: 0;
  right: 0;
}

.quadrant3 {
  background-color: #ff5757;
  bottom: 0;
  left: 0;
}

.quadrant4 {
  background-color: #d1a91a;
  bottom: 0;
  right: 0;
}

.axisLabels {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Distribute items to opposite corners */
  padding: 0 20px;
}

.verticalAxisLabel {
  writing-mode: vertical-lr;
  transform: rotate(180deg); /* Rotate vertical text */
  text-align: center;
  display: flex;
  position: absolute;
  font-size: 11px;
  left: -27px;
}

.horizontalAxisLabel {
  position: absolute;
  bottom: -27px;
  left: 0;
  width: 100%;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
}

.axisLabelTop {
  bottom: 260px;
  left: -5px;
  position: relative;
}

.axisLabelBottom {
  top: 10px;
  left: -5px;
  position: relative;
}
