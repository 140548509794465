.navbar {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 10px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 2em;
}

.brand {
  font-size: 1.2em;
}
.brand span {
  font-weight: 900;
  text-transform: uppercase;
}
.links_list {
  display: flex;
  list-style: none;
}

.links_list li {
  margin-right: 1em;
}

.links_list li a {
  color: #050505;
}

.active {
  border: solid 1px #6193dd;
  padding-left: 5px;
  padding: 0.5em 1em;
}

.logo {
  width: 35%;
}

@media only screen and (max-width: 600px) {
}
