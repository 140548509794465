.create_post {
  text-align: center;
}

.create_post h2 {
  font-size: 2.2em;
}

.create_post p {
  color: #777;
  margin-bottom: 2em;
}

.create_post form {
  max-width: 80%;
  margin: 0 auto;
}
